import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import VideoShot from './video-shot';
import NODATA_ICON from "../assets/images/icons/noresultsFound.png";
import { PublisherPage } from './publisher-page';
import { LeaderBoard } from './leader-board';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { PlatformStore } from '../app/redux/reducers/types';
import { connect } from 'react-redux';
interface Props {
    items: any[];
    videoDuration?: any;
    currentVideoTime?: any;
    videoProgress?: any;
    PlatformStore: PlatformStore;
}

interface Position {
    x: number;
    y: number;
}

let startMouseMove = false;
let isMouseMoving = false;
document.onmouseup = () => {
    isMouseMoving = false;
    startMouseMove = false;
}

enum SwipeDirection {
    HORIZONTAL_LEFT_TO_RIGHT,
    HORIZONTAL_RIGHT_TO_LEFT,
    VERTICAL,
    NONE
}
const FlickScroller = (props: Props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startPosition, setStartPosition] = useState<Position>({ x: -1, y: -1 });
    const flickScrollerContainer = useRef<HTMLDivElement>(null);
    const flickDrawerContainer = useRef<HTMLDivElement>(null);
    const leaderboardContainer = useRef<HTMLDivElement>(null);
    const [topPositions, setTopPositions] = useState<number[]>([]);
    const [movmentDirection, setMovmentDirection] = useState(0);
    const [swipeDirection, setswipeDirection] = useState<SwipeDirection>(SwipeDirection.VERTICAL);
    const [totalWheelDrag, setTotalWheelDrag] = useState(0);
    const [isWheelDragging, setIsWheelDragging] = useState(false);
    /**
     * 0 : Video shot
     * 1 : Publisher view
     * 2 : Leaderboard view
     */
    const [activeScreenState, setActiveScreenState] = useState(0);
    let previousWheelTimer: any;

    useEffect(() => {
        const totalChildren = props.items.length;
        const positions: number[] = [];
        let previousShotIndex = 0;
        if (props.PlatformStore && props.PlatformStore.scrolledGameId) {
            previousShotIndex = props.items?.findIndex(el => el._id === props.PlatformStore.scrolledGameId);
            if (previousShotIndex > 0) {
                setCurrentIndex(previousShotIndex);
            }
        }
        let vh = window.innerHeight;
        for (let index = 0; index < totalChildren; index++) {
            positions.push((index - currentIndex - previousShotIndex) * vh);
        }
        setTopPositions(positions);
        setTimeout(() => {
            updateNodePositions(positions);
        }, 10);
    }, [props.items]);

    const onTouchStart = (event: SyntheticEvent) => {
        event.preventDefault();

        startMouseMove = true;
        const eventData = event.nativeEvent as TouchEvent;
        // Get touch coordinates
        const touch = eventData.touches[0];
        const x = touch.clientX;
        const y = touch.clientY;
        // const eventData = event.nativeEvent as MouseEvent;
        setStartPosition({ x, y });
    }

    const onTouchMove = (event: SyntheticEvent) => {
        if (!startMouseMove) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        const eventData = event.nativeEvent as TouchEvent;
        // Get touch coordinates
        const touch = eventData.touches[0];
        handleSwipe({ x: touch.clientX, y: touch.clientY });
    }

    const onMouseStart = (event: SyntheticEvent) => {
        startMouseMove = true;
        const eventData = event.nativeEvent as MouseEvent;
        setStartPosition({ x: eventData.x, y: eventData.y });
        setswipeDirection(SwipeDirection.NONE)
    }


    const onMouseMove = (event: SyntheticEvent) => {
        if (!startMouseMove) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        const eventData = event.nativeEvent as MouseEvent;
        handleSwipe({ x: eventData.x, y: eventData.y });
    }

    const onMouseEnd = (event: SyntheticEvent) => {
        if (isMouseMoving) {
            updateFlickAction();
        }
        isMouseMoving = false;
        startMouseMove = false;
    }

    const handleSwipe = (eventData: Position) => {
        isMouseMoving = true;
        const flickScroller = flickScrollerContainer.current as HTMLElement;

        if (flickScroller) {
            let hDiff = eventData.x - startPosition.x;
            let vDiff = eventData.y - startPosition.y;
            let isVerticalMovement = Math.abs(vDiff) > Math.abs(hDiff);

            if (isVerticalMovement) {
                if (activeScreenState === 0) {
                    setMovmentDirection(Math.abs(vDiff) / vDiff);
                    const postions = [];
                    for (let index = 0; index < topPositions.length; index++) {
                        postions.push(topPositions[index] + vDiff);
                    }
                    // setFloatingPositions(postions);
                    updateNodePositions(postions);
                    setswipeDirection(SwipeDirection.VERTICAL);
                }
            } else {
                updateDragPosition(hDiff);
                setswipeDirection(hDiff < 0 ? SwipeDirection.HORIZONTAL_RIGHT_TO_LEFT : SwipeDirection.HORIZONTAL_LEFT_TO_RIGHT);
            }
        }
    }

    const updateDragPosition = (drag: number) => {
        if (swipeDirection === SwipeDirection.HORIZONTAL_LEFT_TO_RIGHT) {
            if (activeScreenState === 0) {
                const dragContainer = leaderboardContainer.current as HTMLElement;
                const parentWidth = leaderboardContainer.current?.getBoundingClientRect().width || window.screen.availWidth;
                if (dragContainer) {
                    const position = parentWidth - drag;
                    dragContainer.style.right = `${position}px`;
                    updateActiveVideoPosition(-drag, false);
                }
            } else if (activeScreenState === 1) {
                const dragContainer = flickDrawerContainer.current as HTMLElement;
                const parentWidth = flickScrollerContainer.current?.parentElement.getBoundingClientRect().width || window.screen.availWidth;
                if (dragContainer) {
                    const position = parentWidth - drag;
                    dragContainer.style.left = `${drag}px`;
                    updateActiveVideoPosition(position, false);
                }
            }
        } else if (swipeDirection === SwipeDirection.HORIZONTAL_RIGHT_TO_LEFT) {
            if (activeScreenState === 0) {
                const dragContainer = flickDrawerContainer.current as HTMLElement;
                const parentWidth = flickScrollerContainer.current?.parentElement.getBoundingClientRect().width || window.screen.availWidth;
                if (dragContainer) {
                    const position = parentWidth + drag;
                    dragContainer.style.left = `${position}px`;
                    updateActiveVideoPosition(-drag, false);
                }
            } else if (activeScreenState === 2) {
                const dragContainer = leaderboardContainer.current as HTMLElement;
                const parentWidth = leaderboardContainer.current?.getBoundingClientRect().width || window.screen.availWidth;
                if (dragContainer) {
                    const position = -drag;
                    dragContainer.style.right = `${position}px`;
                    updateActiveVideoPosition(-drag - parentWidth, false);
                }
            }
        }
    }

    const updateNodePositions = (positions: number[]) => {
        const childNodes = flickScrollerContainer.current?.childNodes;
        if (childNodes) {
            let maxLength = childNodes.length;
            if (maxLength > positions.length) {
                maxLength = positions.length;
            }
            for (let index = 0; index < maxLength; index++) {
                const element = childNodes[index] as HTMLDivElement;
                // const elementPosition = element.style.top || '0px';
                element.style.top = `${positions[index]}px`;
                // element.style.visibility = 'visible';
            }
        }
    }

    const updateFlickAction = () => {
        console.log('swipe direction ', swipeDirection, movmentDirection);

        if (swipeDirection === SwipeDirection.VERTICAL && activeScreenState === 0) {

            let activeIndex = currentIndex - 1 * movmentDirection;
            if (activeIndex > props.items.length - 1) {
                activeIndex = 0;
            }
            if (activeIndex < 0) {
                activeIndex = props.items.length - 1;
            }
            // activeIndex = Math.min(activeIndex, props.items.length - 1);
            // activeIndex = Math.max(activeIndex, 0);
            const flickScroller = flickScrollerContainer.current.parentElement as HTMLElement;
            // const containerHeight = flickScroller.getBoundingClientRect().height;
            let vh = window.innerHeight;
            const positions = [];
            console.log('top positoins ', topPositions, vh);

            for (let index = 0; index < topPositions.length; index++) {
                // const element = topPositions[index];
                let positionsIndex = index - activeIndex;
                if (activeIndex > 1 && index < activeIndex - 1) {
                    positionsIndex = topPositions.length - activeIndex + index;
                }
                positions.push(positionsIndex * vh);
            }
            console.log('top positoins ', positions);
            setTopPositions(positions);
            setCurrentIndex(activeIndex);
            updateNodePositions(positions);

            // Add smooth animation
            flickScroller.classList.add('smooth');
            const timeoutTimer = setTimeout(() => {
                flickScroller.classList.remove('smooth');
                clearTimeout(timeoutTimer);
            }, 500);
        } else if (swipeDirection === SwipeDirection.HORIZONTAL_RIGHT_TO_LEFT) {
            const dragContainer = flickDrawerContainer.current as HTMLElement;
            const flickDragContainer = flickDrawerContainer.current as HTMLElement;
            if (flickDragContainer) {
                const parentWidth = flickDragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
                const currentPositionPx = dragContainer.style.left;
                const currentPosition = Number(currentPositionPx.substring(0, currentPositionPx.length - 2)) || 0;
                let videoContainerPosition = 0;
                // if ((parentWidth - currentPosition) / parentWidth > 0.15) {
                if (activeScreenState === 2) {
                    closeLeaderboard();
                    setActiveScreenState(0);

                } else {
                    dragContainer.style.left = `0`;
                    videoContainerPosition = parentWidth;
                    setActiveScreenState(1);

                }
                // } else {
                //     dragContainer.style.left = `${parentWidth}px`;
                // }
                const videoContainer = flickScrollerContainer.current.children[currentIndex] as HTMLElement;
                updateActiveVideoPosition(videoContainerPosition, true);
                // Add smooth animation
                flickDragContainer.classList.add('smooth');
                videoContainer.classList.add('smooth');
                const timeoutTimer = setTimeout(() => {
                    flickDragContainer.classList.remove('smooth');
                    videoContainer.classList.remove('smooth');
                    clearTimeout(timeoutTimer);
                }, 500);
            }
        } else if (swipeDirection === SwipeDirection.HORIZONTAL_LEFT_TO_RIGHT) {
            let dragContainer = leaderboardContainer.current as HTMLElement;
            let flickDragContainer = leaderboardContainer.current as HTMLElement;
            let parentWidth = flickDragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
            if (flickDragContainer) {
                const currentPositionPx = dragContainer.style.right;
                const currentPosition = Number(currentPositionPx.substring(0, currentPositionPx.length - 2)) || 0;
                let videoContainerPosition = 0;
                console.log('.... active screen state ', activeScreenState, currentPositionPx, parentWidth, (parentWidth - currentPosition), (parentWidth - currentPosition) / parentWidth);

                // if ((parentWidth - currentPosition) / parentWidth > 0.15) {
                console.log('......... entered to close...');

                if (activeScreenState === 1) {
                    closeDrawer();
                    videoContainerPosition = 0;
                    setActiveScreenState(0);
                } else {
                    dragContainer.style.right = `0`;
                    videoContainerPosition = -parentWidth;
                    setActiveScreenState(2);
                }
                // } else {
                //     dragContainer.style.right = `${parentWidth}px`;
                // }
                const videoContainer = flickScrollerContainer.current.children[currentIndex] as HTMLElement;
                updateActiveVideoPosition(videoContainerPosition, true);
                // Add smooth animation
                flickDragContainer.classList.add('smooth');
                videoContainer.classList.add('smooth');
                const timeoutTimer = setTimeout(() => {
                    flickDragContainer.classList.remove('smooth');
                    clearTimeout(timeoutTimer);
                }, 500);
            }
            // setActiveScreenState(2);
        }
    }

    const getVideoDuration = (duration: any) => {
        if (duration) {
            props.videoDuration(duration)
        }
    }

    const getProgress = (progress: any) => {
        if (progress) {
            props?.videoProgress(progress)
        }
    }

    const closeDrawer = () => {
        const dragContainer = flickDrawerContainer.current as HTMLElement;
        const parentWidth = dragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
        dragContainer.style.left = `${parentWidth}px`;

        // Add smooth animation
        dragContainer.classList.add('smooth');
        const timeoutTimer = setTimeout(() => {
            dragContainer.classList.remove('smooth');
            clearTimeout(timeoutTimer);
        }, 500);
        setIsWheelDragging(false);
        updateActiveVideoPosition(0, true);
        setActiveScreenState(0);
    }

    const closeLeaderboard = () => {
        const dragContainer = leaderboardContainer.current as HTMLElement;
        const parentWidth = dragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
        dragContainer.style.right = `${parentWidth}px`;
        // Add smooth animation
        dragContainer.classList.add('smooth');
        const timeoutTimer = setTimeout(() => {
            dragContainer.classList.remove('smooth');
            clearTimeout(timeoutTimer);
        }, 500);
        setIsWheelDragging(false);
        updateActiveVideoPosition(0, true);
        setActiveScreenState(0);
    }

    const openLeaderboard = () => {
        const dragContainer = leaderboardContainer.current as HTMLElement;
        dragContainer.style.right = `0px`;
        // Add smooth animation
        dragContainer.classList.add('smooth');
        const timeoutTimer = setTimeout(() => {
            dragContainer.classList.remove('smooth');
            clearTimeout(timeoutTimer);
        }, 500);
    }

    const openDrawer = (status: any) => {
        console.log('status ', status);

        if (status) {
            console.log("publisher swipe");
            const dragContainer = flickDrawerContainer.current as HTMLElement;
            console.log("drag container : ", dragContainer);
            const parentWidth = dragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
            console.log("parentWidth : ", parentWidth);
            dragContainer.style.left = `${0}px`;
            dragContainer.classList.add('smooth');
            const timeoutTimer = setTimeout(() => {
                dragContainer.classList.remove('smooth');
                clearTimeout(timeoutTimer);
            }, 600);
        }
    }

    const handleDragOnWheel = (drag: number) => {
        if (swipeDirection === SwipeDirection.HORIZONTAL_LEFT_TO_RIGHT) {
            const dragContainer = leaderboardContainer.current as HTMLElement;
            const parentWidth = window.screen.availWidth;
            if (dragContainer) {
                const position = parentWidth - totalWheelDrag;
                dragContainer.style.right = `${position}px`;
            }
        } else {
            const dragContainer = flickDrawerContainer.current as HTMLElement;
            const parentWidth = dragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
            if (dragContainer) {
                const position = parentWidth + totalWheelDrag;
                console.log('width ', parentWidth, drag, position);

                dragContainer.style.left = `${position}px`;
            }
        }
    }

    const handleSwipeOnWheel = (eventData: WheelEvent) => {
        isMouseMoving = true;
        const flickScroller = flickScrollerContainer.current as HTMLElement;

        if (flickScroller) {
            let hDiff = eventData.deltaX * -1;
            let vDiff = eventData.deltaY * -1;
            let isVerticalMovement = Math.abs(vDiff) > Math.abs(hDiff);
            // totalWheelDrag+=vDiff;
            if (isVerticalMovement) {
                if (activeScreenState === 0) {
                    setTotalWheelDrag(totalWheelDrag + vDiff);
                    setMovmentDirection(Math.abs(vDiff) / vDiff);
                    const positions: number[] = [];
                    for (let index = 0; index < topPositions.length; index++) {
                        positions.push(topPositions[index] + totalWheelDrag);
                    }
                    updateNodePositions(positions);
                    // setTopPositions(positions);
                    setswipeDirection(SwipeDirection.VERTICAL);
                }
            } else {
                setTotalWheelDrag(totalWheelDrag + hDiff);
                handleDragOnWheel(hDiff);
                setswipeDirection(hDiff < 0 ? SwipeDirection.HORIZONTAL_RIGHT_TO_LEFT : SwipeDirection.HORIZONTAL_LEFT_TO_RIGHT);
            }
        }
    }

    const endDragAction = () => {
        if (swipeDirection === SwipeDirection.VERTICAL) {

            let activeIndex = currentIndex - 1 * movmentDirection;
            activeIndex = Math.min(activeIndex, props.items.length - 1);
            activeIndex = Math.max(activeIndex, 0);
            const flickScroller = flickScrollerContainer.current as HTMLElement;
            const containerHeight = flickScroller.getBoundingClientRect().height;
            let vh = window.innerHeight;
            const positions = [];
            console.log('top positoins ', topPositions, vh);

            for (let index = 0; index < topPositions.length; index++) {
                const element = topPositions[index];
                let positionsIndex = index - activeIndex;
                positions.push(positionsIndex * vh);
            }
            console.log('top positoins ', positions);
            setTopPositions(positions);
            setCurrentIndex(activeIndex);
            updateNodePositions(positions);

            // Add smooth animation
            flickScroller.classList.add('smooth');
            const timeoutTimer = setTimeout(() => {
                flickScroller.classList.remove('smooth');
                clearTimeout(timeoutTimer);
            }, 500);
        } else if (swipeDirection === SwipeDirection.HORIZONTAL_RIGHT_TO_LEFT) {
            const dragContainer = flickDrawerContainer.current as HTMLElement;
            const flickDragContainer = flickDrawerContainer.current as HTMLElement;
            if (flickDragContainer) {
                const parentWidth = flickDragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
                const currentPositionPx = dragContainer.style.left;
                const currentPosition = Number(currentPositionPx.substring(0, currentPositionPx.length - 2)) || 0;
                dragContainer.style.left = `0`;

                // if ((parentWidth - currentPosition) / parentWidth > 0.15) {
                //     dragContainer.style.left = `0`;
                // } else {
                //     dragContainer.style.left = `${parentWidth}px`;
                // }
                // Add smooth animation
                flickDragContainer.classList.add('smooth');
                const timeoutTimer = setTimeout(() => {
                    flickDragContainer.classList.remove('smooth');
                    clearTimeout(timeoutTimer);
                }, 500);
            }
        } else if (swipeDirection === SwipeDirection.HORIZONTAL_LEFT_TO_RIGHT) {
            const dragContainer = leaderboardContainer.current as HTMLElement;
            const flickDragContainer = leaderboardContainer.current as HTMLElement;
            if (flickDragContainer) {
                const parentWidth = flickDragContainer.parentElement?.getBoundingClientRect().width || window.screen.availWidth;
                const currentPositionPx = dragContainer.style.right;
                const currentPosition = Number(currentPositionPx.substring(0, currentPositionPx.length - 2)) || 0;
                dragContainer.style.right = `0`;
                // if ((parentWidth - currentPosition) / parentWidth > 0.15) {
                //     dragContainer.style.right = `0`;
                // } else {
                //     dragContainer.style.right = `${parentWidth}px`;
                // }
                // Add smooth animation
                flickDragContainer.classList.add('smooth');
                const timeoutTimer = setTimeout(() => {
                    flickDragContainer.classList.remove('smooth');
                    clearTimeout(timeoutTimer);
                }, 500);
            }
        }
    }
    const OnMouseWheel = (event: SyntheticEvent) => {
        const eventData = event.nativeEvent as WheelEvent;
        if (isWheelDragging) {
            return;
        }
        handleSwipeOnWheel(eventData);
        if (previousWheelTimer) {
            clearTimeout(previousWheelTimer);
        }
        if (Math.abs(totalWheelDrag) > 50) {
            setIsWheelDragging(true);
            endDragAction();
            setTotalWheelDrag(0);
            previousWheelTimer = setTimeout(() => {
                if (swipeDirection === SwipeDirection.VERTICAL) {
                    setIsWheelDragging(false);
                }
            }, 1000);
        }
    }

    const updateActiveVideoPosition = (position: number, isSmooth: boolean) => {
        const videoContainer = flickScrollerContainer.current.children[currentIndex] as HTMLElement;
        if (videoContainer) {
            videoContainer.style.transform = `translatex(${-position}px)`;
            if (isSmooth) {
                // Add smooth animation
                videoContainer.classList.add('smooth');
                const timeoutTimer = setTimeout(() => {
                    videoContainer.classList.remove('smooth');
                    clearTimeout(timeoutTimer);
                }, 500);
            }
        }
    }

    const resetPositions = () => {
        const flickScroller = (flickScrollerContainer.current as HTMLElement).parentElement;
        flickScroller.classList.add('smooth');
        const timeoutTimer = setTimeout(() => {
            flickScroller.classList.remove('smooth');
            clearTimeout(timeoutTimer);
        }, 500);
        const totalChildren = props.items.length;
        const positions: number[] = [];
        let vh = window.innerHeight;
        for (let index = 0; index < totalChildren; index++) {
            positions.push(index * vh);
        }
        setTopPositions(positions);
        setTimeout(() => {
            updateNodePositions(positions);
        }, 10);
    }
    const scrollToTop = () => {
        console.log('scroll top');
        setCurrentIndex(0);
        resetPositions();
    }

    return (
        <div className='w-100 flick-scroller' onWheel={OnMouseWheel}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onMouseEnd}
            onMouseDown={onMouseStart} onMouseMove={onMouseMove} onMouseUp={onMouseEnd}>
            {/* <div className={`${props.items[currentIndex]?.type === 'ad'?'d-block':'d-none'}`} id="container-1ef445372f939ed035be83664121e056"></div> */}
            <div ref={flickScrollerContainer} className='' style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }}>
                {
                    props.items?.length > 0 && topPositions.length > 0 && <>
                        {
                            props.items.map((el, index) => <div className='video-shot'>
                                <VideoShot isInView={currentIndex === index} type={el.type} data={el} canScrollToTop={currentIndex > 1}
                                    publisherSwipeModalStatus={openDrawer} onScrollToTop={scrollToTop}
                                    duration={(e: any) => getVideoDuration(e)} progress={(e: any) => getProgress(e)} />
                            </div>)
                        }
                    </>
                }
                {props?.items?.length == 0 && (
                    <div className="col-6 mt-5 mx-auto align-items-center">
                        <img loading="lazy" src={NODATA_ICON} alt="" className='mt-5 w-75 mx-3 mx-md-5 p-md-5 float-center' style={{ left: "25px !important" }} />
                    </div>
                )}
            </div>
            <FontAwesomeIcon onClick={() => openDrawer(true)} icon={faArrowRight} style={{ fontSize: 30, right: 10, top: 'calc(50% - 15px)' }} className='d-none d-lg-block position-absolute pointer' />
            <FontAwesomeIcon onClick={openLeaderboard} icon={faArrowLeft} style={{ fontSize: 30, left: 10, top: 'calc(50% - 15px)' }} className='d-none d-lg-block position-absolute pointer' />
            <div className="flick-drawer h-100 mp-scroll-bar-width overflow-hidden" ref={flickDrawerContainer}>
                <PublisherPage publisherData={props.items[currentIndex]} publisherModalStatus={closeDrawer} />
            </div>
            <div style={{
                maxHeight: '100vh'
            }} className='flick-drawer-left moplay-corn-bg' ref={leaderboardContainer}>
                <i className="f-18 bi bi-arrow-left d-block p-3 moplay-red-bg text-white" style={{ cursor: 'pointer' }} onClick={closeLeaderboard}></i>
                {/* <h2 className='text-center pb-2 border-bottom'>{props.items[currentIndex]?.name}</h2> */}
                <LeaderBoard gameId={props.items[currentIndex]?._id} type={props.items[currentIndex]?.leaderboard_type} />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    PlatformStore: state.PlatformStore
});

export default connect(mapStateToProps)(FlickScroller);