import React from 'react'
import { BackBtnTitle } from '../components/back-btn-title'

export const AboutUs = () => {
    return (
        <div className={`container px-3  position-relative`} >
            <BackBtnTitle text='ABout Us' path='/'></BackBtnTitle>
            <div className='row'>
                <div className='col-md-12 p-4'>
                    <div className="px-4">
                        <b>Our Vision</b>
                        <p>At MOPLAY, we're revolutionizing the gaming landscape by creating the ultimate all-in-one destination for gaming enthusiasts. Born from the innovative minds at QUICKPLAY TECHNOLOGIES, MOPLAY addresses a crucial gap in the market: the fragmentation of gaming experiences across multiple platforms. Our vision is to unite gamers, content creators, and gaming communities in one seamless, comprehensive platform.</p>

                        <b>Who We Are</b>
                        <p>
                            MOPLAY is the flagship product of QUICKPLAY TECHNOLOGIES, a forward-thinking startup dedicated to transforming the gaming industry.
                            Our founding team brings together over 60 years of combined expertise across:
                            <ul>
                                <li>Software and game development</li>
                                <li>Customer experience optimization</li>
                                <li>Business development</li>
                                <li>Content partnerships and strategic alliances</li>
                            </ul>
                            This wealth of experience enables us to understand not just the technical aspects of gaming, but also the community's needs and aspirations.
                        </p>

                        <b>Our Mission</b>
                        <p>We're on a mission to create the most engaging and comprehensive gaming community online. MOPLAY isn't just another gaming platform – it's a revolutionary ecosystem where gaming enthusiasts can Meet, Inform, Read, Play, Chat, and do so much more, all in one place.</p>

                        <b>What Sets Us Apart</b>
                        <p>
                            MOPLAY is built on four core pillars that define our unique value proposition:
                            <ol>
                                <li>
                                    <b>Comprehensive Integration</b>
                                    <div>
                                        We've seamlessly merged essential gaming experiences into one platform:
                                        <ul>
                                            <li>Curated gaming news from trusted sources</li>
                                            <li>Browser-based gameplay options</li>
                                            <li>Rich social interaction features</li>
                                            <li>Competitive gaming opportunities</li>
                                            <li>Future expansion into cloud gaming and gaming commerce</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <b>Community-First Approach</b>
                                    <div>
                                        Our platform is designed to foster meaningful connections through:
                                        <ul>
                                            <li>Customizable user profiles and avatars</li>
                                            <li>Game-specific forums and discussion boards</li>
                                            <li>Real-time chat functionality</li>
                                            <li>User-generated content sharing</li>
                                            <li>Community-driven reviews and discussions</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <b>Innovation-Driven Development</b>
                                    <div>
                                        Our technology stack is built for the future, featuring:
                                        <ul>
                                            <li>Scalable cloud infrastructure</li>
                                            <li>Real-time data processing</li>
                                            <li>Advanced security protocols</li>
                                            <li>AI-driven personalization</li>
                                            <li>Cross-platform compatibility</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <b>Future-Ready Roadmap</b>
                                    <div>
                                        We're constantly evolving, with planned expansions into:
                                        <ul>
                                            <li>eSports tournament platforms</li>
                                            <li>Cloud gaming services</li>
                                            <li>Gaming commerce marketplace</li>
                                            <li>Downloadable game distribution</li>
                                            <li>Emerging gaming technologies</li>
                                        </ul>
                                    </div>
                                </li>
                            </ol>
                        </p>

                        <b>Our Commitment</b>
                        <p>
                            As gaming enthusiasts ourselves, we understand the passion that drives our community.
                            We're committed to:
                            <ul>
                                <li>Creating a safe and inclusive gaming environment</li>
                                <li>Delivering cutting-edge features and experiences</li>
                                <li>Supporting both casual and competitive gamers</li>
                                <li>Fostering meaningful connections between players</li>
                                <li>Continuously evolving based on community feedback</li>
                            </ul>
                        </p>

                        <b>Join Our Journey</b>
                        <p>MOPLAY is more than just a platform – it's the future of gaming communities. Whether you're a casual gamer, competitive player, content creator, or gaming enthusiast, there's a place for you in our growing community.</p>

                        <b>Connect With Us</b>
                        <div>
                            <p>We're always eager to hear from our community, potential partners, and fellow gaming enthusiasts:
                                <ul style={{listStyle: 'none'}}>
                                    <li>
                                        Email: info@moplay.in

                                    </li>
                                    <li>
                                        Phone: +(91) 95827 33861

                                    </li>
                                    <li>
                                        Social Media: @moPlay

                                    </li>
                                    <li>

                                        Website: www.moplay.in
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <p>MOPLAY is a product of QUICKPLAY TECHNOLOGIES. Together, we're building the future of gaming, one community at a time.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}
