import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import appConfig from '../config/constant'
import ScoreBar from '../components/score-bar'
import { GameById } from '../app/service/game.service'
import { connect } from 'react-redux'
import { Game } from '../app/model/dto/res/game'
import { GamePlayType, PlatformActionType, PlatformScreen } from '../app/redux/reducers/types'
import { GameStats } from '../app/service/gamestats.service'
import { Resources } from '../app/utility/resources'
import { User } from '../app/model/dto/res/user'

interface Props {
    setActiveGame?: (data: Game) => void;
    updateActiveScreen?: (screen: PlatformScreen) => void;
    gameType?: GamePlayType;
    user?: User
}

const PlayGame = (props: Props) => {
    const [gameData, setGameData] = useState<any>([])
    const [gamePlaylink, setGamePlaylink] = useState('');
    const [isGameLoaded, setIsGameLoaded] = useState(false);
    const [isLogoLoaded, setIsLogoLoaded] = useState(false);
    // const [scorebarEnabled, setScorebarEnabled] = useState(false);
    let { id } = useParams<{ id: string }>();
    const gameId = id as string;
    const url = new URL(window.location.href);
    const challengeId = url.searchParams.get('challengeId');
    let isStatsUpdated = false;

    useEffect(() => {
        gameDetailsById(gameId);
        if (props.updateActiveScreen) {
            props.updateActiveScreen(PlatformScreen.IN_GAME);
        }

        if (!isStatsUpdated) {
            isStatsUpdated = true;
        }

        return () => {
            if (props.updateActiveScreen) {
                props.updateActiveScreen(PlatformScreen.MATCH_SUMMARY);
            }
        }
    }, []);

    const gameDetailsById = (data: any) => {
        const gameId = data;
        GameById(gameId).then((res: any) => {
            setGameData(res);
            if (res.type === 2) {
                setGamePlaylink(`${res.external_link}?data1=${props.user?._id}&data2=${gameId}`);
            } else {
                setGamePlaylink(`${appConfig?.GAME_URL}${gameId}/index.html`);
            }
            if (props.setActiveGame) {
                props.setActiveGame(res);
            }
            (window as any).gtag('event', 'start_game_play', {
                'game_name': res.name
            });
        });
    }

    const gameLoaded = () => {
        setIsGameLoaded(true);
    }

    const onLogoLoaded = () => {
        setIsLogoLoaded(true);
    }
    return (
        <div className='container-fluid px-0 moplay-play-game'>
            {(!isGameLoaded || !isLogoLoaded) && <div className='splash'>
                <img loading="lazy" onAnimationEnd={onLogoLoaded} src={Resources.MOLOGO_PNG} alt="" />
            </div>}
            {gameData && isGameLoaded && isLogoLoaded && <ScoreBar gameData={gameData} gameResultId={gameId} challengeId={challengeId} />}
            {gamePlaylink && <iframe onLoad={gameLoaded} src={gamePlaylink} className='mp-game' allowFullScreen></iframe>}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    gameType: state.PlatformStore.gameType,
    user: state.UserStore.user
    // multiplayerParticipants: state.PlatformStore.multiplayerParticipants,
    // opponentGameData: state.PlatformStore.multiplayerOpponentGameData
});

const mapDispatchToProps = (dispatch: any) => ({
    setActiveGame: (data: Game) => dispatch({ data, type: PlatformActionType.ACTIVE_GAME }),
    updateActiveScreen: (data: PlatformScreen) => dispatch({ data, type: PlatformActionType.UPDATE_ACTIVE_SCREEN })
})
export default connect(mapStateToProps, mapDispatchToProps)(PlayGame);