import React from 'react'
import { Resources } from '../app/utility/resources'

export const Invite = () => {
    return (
        <div className="d-flex p-3 justify-content-center">
            <a href={`whatsapp://send?text=Hey, checkout this new game platform http://${window.location.host}`} className="p-3 d-flex flex-column text-white align-items-center text-decoration-none">
                <img loading="lazy" src={Resources.SOCIAL_ICON_WHATSAPP} alt="" width={75} />
                <p className='text-dark'>Share on whatsapp</p>
            </a>
        </div>
    )
}
