import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { BlogEditorElement } from './types'
import { Resources } from '../../utility/resources';

interface Props {
  data: BlogEditorElement;
  onUpdate: (element: BlogEditorElement) => void;
}
export const ImageElement = (props: Props) => {
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState('');
  const [elementData, setElementData] = useState<BlogEditorElement>();

  useEffect(() => {
    setElementData(props.data);
  }, []);


  const onUploadFile = () => {
    if (fileInputRef.current) {
      const fileInputHandler = fileInputRef.current as HTMLInputElement;
      fileInputHandler.click();
    }
  }

  const onChangeImage = (event: SyntheticEvent) => {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement.files) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.result) {
          setImagePreview(fileReader.result as string);
        }
      };
      fileReader.readAsDataURL(inputElement.files[0]);
      if (elementData) {
        const updatedData = { ...elementData, content: inputElement.files[0] };
        setElementData(updatedData);
        if (props.onUpdate && updatedData) {
          props.onUpdate(updatedData);
        }
      }
    }
  }

  return (
    <div className='blog-img'>
      {
        elementData && !elementData.content && !elementData.content &&
        <div className='placeholder' onClick={onUploadFile}>
          Click to upload an image
        </div>
      }
      {
        imagePreview && <img loading="lazy" src={imagePreview} />
      }
      {
        !imagePreview && elementData?.content && <img loading="lazy" src={elementData.content as string} />
      }
      <input ref={fileInputRef} type="file" name="" id="" style={{ display: 'none' }} accept='image/*' onChange={onChangeImage} />
    </div>
  )
}
