import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { BlogService } from '../service/blog.service';
import { Blog } from '../model/blog';
import DisplayPicture from '../components/display-picture';
// import { AppConfig } from '../../config';
import { connect } from 'react-redux';
import { UserData } from '../redux/types';
import appConfig from '../../config/constant';
import { BlogContent } from './blog-content';

interface Props {
    UserData?: UserData;
}
const BlogHome = (props: Props) => {
    let isLoaded = false;
    const [availableBlogs, setAvailableBlogs] = useState<Blog[]>();
    useEffect(() => {
        loadAllBlogs();
        // isLoaded = true;
    }, [props.UserData]);

    const loadAllBlogs = async () => {
        const result = await BlogService.getAll(appConfig.blog_client_id);
        if (result && result.length) {
            result.forEach((element, index) => {
                if (element.poster_url) {
                    element.poster_url_display = `${appConfig.mediaBaseUrl}/${element.poster_url}`;
                }
                switch (index) {
                    case 0:
                        element.position_css = 'item1'
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        element.position_css = `item${index + 1}`
                        break;
                    default:
                        break;
                }
            });
        }
        setAvailableBlogs(result);
    }

    return (
        <div>
            {appConfig.blog_client_id &&
                <>
                    {/* {props.UserData?.can_write && <div className='shadow-bottom-reg pv-10 bg-dark clr-white flex-box align center justify content end' style={{ height: 60 }}>
                        <NavLink className={'clr-white mr-10 btn main outline rounded'} to={'/editor/0'}>Write New</NavLink>
                    </div>} */}
                    <div className="m-container pv-10 blog-layout" style={{ paddingBottom: 250 }}>
                        {availableBlogs?.map(el => <NavLink to={`blog/${el.blog_url}`} key={el._id} className={`${el.position_css} `}>
                            <BlogContent blog={el}></BlogContent>
                        </NavLink>)}
                    </div>
                </>
            }
        </div>
    )
}


const mapStateToProps = (state: any) => ({
    UserData: state.UserStore.user
});
export default connect(mapStateToProps)(BlogHome);