import React, { useEffect, useState } from 'react'

interface Props {
  first_name: string;
  last_name: string;
  profile_pic?: string;
  width?: number;
  height?: number;
  text_size?: number;
  bg_clr?: string;
}

const DisplayPicture = (props: Props) => {
  // const [profilePic, setProfilePic] = useState('');
  const [styleObj, setStyleObj] = useState({});

  useEffect(() => {
    // if (props.profile_pic) {
    //   if (props.profile_pic.startsWith('data:image')) {
    //     setProfilePic(props.profile_pic);
    //   } else if (typeof props.profile_pic === 'string') {
    //     setProfilePic(props.profile_pic);
    //   } else {
    //     setProfilePic(props.profile_pic);
    //   }
    // }
    setStyleObj({ width: props.width || 100, height: props.height || 100, borderRadius: '50%', backgroundColor: props.bg_clr || '#eef1f2' });
  }, [props]);

  return (
    <div>
      {props.profile_pic && <img loading="lazy" src={props.profile_pic} style={{ ...styleObj, objectFit: 'contain' }}></img>}
      {
        !props.profile_pic &&
        <div className="flex-box justify center align center" style={styleObj}>
          <h2 style={{fontSize: props.text_size}}>{props.first_name && props.first_name[0]}</h2>
        </div>
      }
    </div>
  )
}

export default DisplayPicture