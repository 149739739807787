import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ScoreSubmitReq } from '../app/model/score-submit.req';
import { ScoreService } from '../app/service/score.service';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { GamePlayType, PlatformActionType } from '../app/redux/reducers/types';
import { MultiplayerParticipant } from '../app/model/dto/socket/multiplayer-participant';
import { MultiplayerGameData } from '../app/model/dto/socket/multiplayer-game-data';
import { SocketSvc } from '../app/service/socket.service';
import { MultiplayerGameSummary } from '../app/model/dto/socket/multiplayer-game-summary';
import { PlayerMove, PlayerMoveReq } from '../app/model/dto/req/player-move';
import { MultiplayerBot } from '../app/model/dto/res/multiplayer-bot';
import { GameStats } from '../app/service/gamestats.service';
import { Resources } from '../app/utility/resources';
import { User } from '../app/model/dto/res/user';
interface Props {
    gameResultId?: any;
    maxGameTime?: number;
    gameData: any;
    challengeId?: any;
    updateGameScore?: (data: ScoreSubmitReq) => {};
    gameType?: GamePlayType;
    multiplayerParticipants?: MultiplayerParticipant[];
    opponentGameData?: MultiplayerGameData;
    // updateMultiplayerMatchSummary?: (data: MultiplayerGameSummary) => void;
    updateMultiplayerUserData?: (data: MultiplayerGameData) => void;
    multiplayerGameEnd?: () => void;
    multiplayerBotData?: MultiplayerBot;
    userData: User;
}
const ScoreBar = (props: Props) => {
    const url = new URL(window.location.href);
    const type = url.searchParams.get('type');

    const [gameExitPopup, setGameExitPopup] = useState<boolean>(false);
    const [userGameScore, setUserGameScore] = useState(0);
    const [gameTimer, setGameTimer] = useState(0);
    const [gameMaxTime, setGameMaxTime] = useState(props?.gameData?.maximum_game_time || 120);
    const [lapsedTime, setLapsedTime] = useState(0);
    const navigation = useNavigate();
    // const [userData, setUserData] = useState<any>(null);
    const [opponentUser, setOpponentUser] = useState<MultiplayerParticipant>();
    const [playerMoves, setPlayerMoves] = useState<PlayerMove[]>([]);
    const [opponentScore, setOpponentScore] = useState(0);
    const [gameStatsId, setGameStatsId] = useState('');
    const [scorebarEnabled, setScorebarEnabled] = useState(false);
    const [showCloseBtn, setShowCloseBtn] = useState(false);

    let isLoaded = false;
    useEffect(() => {
        if (!isLoaded) {
            uploadStats();
            setTimeout(() => {
                setShowCloseBtn(true);
            }, 10000);
        }
        isLoaded = true;
    }, []);

    useEffect(() => {
        if (props?.gameData?.maximum_game_time) {
            // setGameMaxTime(5);
            setGameMaxTime(props.gameData.maximum_game_time);
            // if (gameMaxTime === 120)
            //     setGameMaxTime(Math.random() * 25);
        }

        if (props?.gameData && props.userData) {
            const scorebarAvailable = props?.gameData.game_modes.findIndex((el: number) => el === 4) > -1;
            setScorebarEnabled(scorebarAvailable);
        }
    }, [props.gameData])

    useEffect(() => {
        if (props.multiplayerParticipants && props.multiplayerParticipants.length >= 2) {
            setOpponentUser(props.multiplayerParticipants[1]);
        }
    }, [props?.multiplayerParticipants]);

    useEffect(() => {
        const remainingTime = gameMaxTime - lapsedTime;
        setGameTimer(remainingTime);

        setPlayerMoves([...playerMoves, { time: lapsedTime, score: userGameScore }]);
        // go to match result when the timer is 0
        if (remainingTime < 1 && scorebarEnabled) {
            handleGameOver();
        }

        // multiplayer bot
        if (props.gameType === GamePlayType.MULTIPLAYER_BOT) {
            const previousMoves = props.multiplayerBotData.moves;
            const currentTimeMove = previousMoves.find(el => el.time > lapsedTime);
            if (currentTimeMove) {
                setOpponentScore(currentTimeMove.score);
            }
        }
    }, [lapsedTime]);

    useEffect(() => {
        const intervalTimer = setInterval(() => {
            setLapsedTime(prevTime => {
                return prevTime + 1
            });
        }, 1000);
        return () => {
            clearInterval(intervalTimer);
        }


    }, []);

    useEffect(() => {
        if (props.gameType === GamePlayType.MULTIPLAYER_BOT && props.multiplayerBotData) {
            const { name, profile_pic } = props.multiplayerBotData;
            setOpponentUser({
                name, profile_pic,
                id: null,
                room_id: null
            })
        }
    }, [props.gameType, props.multiplayerBotData]);

    useEffect(() => {
        if (props.opponentGameData) {
            setOpponentScore(props.opponentGameData.score);
        }
    }, [props.opponentGameData]);


    const onClose = () => {
        setGameExitPopup(true);
    }

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        // Cleanup
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const uploadStats = async () => {
        const res = await GameStats.uploadStats(props.gameResultId, props.gameType);
        setGameStatsId(res);
    }
    const handleMessage = (event: any) => {
        const eventData = event.data;
        if (eventData.type === 'game_score') {
            if (props.updateMultiplayerUserData && props.gameType === GamePlayType.MULTIPLAYER) {
                props.updateMultiplayerUserData({ meta: null, score: userGameScore, status: 1, time: lapsedTime });
            }
            setUserGameScore(eventData.score);
            const remainingTime = gameMaxTime - lapsedTime;
            if (remainingTime > 0) {
                if (props?.multiplayerParticipants[1]) {
                    SocketSvc.sendGameScore({ score: eventData.score, meta: null, time: lapsedTime, status: 1 }, props.multiplayerParticipants[1].room_id)
                }
            }
        } else if (eventData.type === 'final_score') {
            setUserGameScore(eventData.score);
            sumbitScore(eventData.score);
        }
    };

    const handleGameOver = async () => {
        // submit score
        const scoreData: ScoreSubmitReq = {
            score: userGameScore,
            player_id: props?.userData?._id,
            game_id: props?.gameResultId
        }

        if (props?.updateGameScore) {
            props.updateGameScore(scoreData);
        }
        await ScoreService.submitScore(scoreData);
        if (gameStatsId) {
            await GameStats.updateStats(gameStatsId, lapsedTime);
        }
        if (props.gameType === GamePlayType.MULTIPLAYER || props.gameType === GamePlayType.MULTIPLAYER_BOT) {
            if (props.updateMultiplayerUserData) {
                props.updateMultiplayerUserData({ meta: null, score: userGameScore, status: 1, time: lapsedTime });
            }
            SocketSvc.endGame({ score: userGameScore, meta: null, time: lapsedTime, status: 1 }, props.multiplayerParticipants[0].room_id);
            if (props.gameType === GamePlayType.MULTIPLAYER_BOT && props.multiplayerGameEnd) {
                props.multiplayerGameEnd();
            }
            navigation(`/summary/${props?.gameResultId}`)
        } else {
            try {
                if (userGameScore > 0) {
                    const playerMovesReq: PlayerMoveReq = {
                        game_id: props.gameResultId,
                        moves: playerMoves,
                        player_id: props.userData?._id
                    }

                    await ScoreService.submitPlayerMoves(playerMovesReq);
                }
            } catch (error) {

            }
            navigation(`/result/${props?.gameResultId}${props?.challengeId ? `?challengeId=${props?.challengeId}` : `?type=${type}`}`);
        }

    }

    const configGameExit = async () => {
        if (props.multiplayerParticipants[0]) {
            let roomId = props.multiplayerParticipants[0].room_id;
            SocketSvc.exitGameRoom({ gameId: props?.gameResultId, roomId, score: userGameScore, userId: props.userData?._id }, props.multiplayerParticipants[0].room_id);
        }

        if (gameStatsId) {
            await GameStats.updateStats(gameStatsId, lapsedTime);
        }
        navigation(`/detail/${props.gameResultId}`);
    }

    const sumbitScore = async (score: number) => {
        const scoreData: ScoreSubmitReq = {
            score: score,
            player_id: props.userData?._id,
            game_id: props?.gameResultId
        }

        console.log('score data ', scoreData);

        if (props?.updateGameScore) {
            props.updateGameScore(scoreData);
        }
        await ScoreService.submitScore(scoreData);
    }

    return (
        <>
            <div className='moplay-primary-bg h-50vh px-2'>
                <div className='d-flex justify-content-between  py-1'>
                    <div className='d-flex justify-content-start align-items-center'>
                        {scorebarEnabled &&
                            <>
                                <div className='text-white px-2 py-1 rounded-pill my-2'><span>Score  :</span><span className='fw-bold'> {userGameScore}</span></div>
                                <div className='text-white px-2 py-1 rounded-pill my-2'><FontAwesomeIcon color={'#ffe168'} icon={faClock} /><span className='fw-bold'> {gameTimer}</span></div>
                            </>
                        }
                        {
                            !scorebarEnabled && <span className="text-white">
                                {props.userData?.name}
                            </span>
                        }
                    </div>
                    {
                        opponentUser && scorebarEnabled &&
                        <div className="d-flex">
                            <div className='text-white px-2 py-1 rounded-pill my-2'><span>{opponentUser.name}  :</span><span className='fw-bold'> {opponentScore || 0}</span></div>
                        </div>
                    }
                    <div className='d-flex justify-content-end'>
                        {showCloseBtn && <i className="bi bi-x text-white fs-4 mp-cursor-pointer" onClick={onClose}></i>}
                    </div>
                </div>
            </div>
            {/* {
                !scorebarEnabled && <span onClick={onClose} className='position-absolute bg-dark d-flex align-items-center justify-content-center pointer' style={{
                    right: 10, top: 10, borderRadius: '50%', height: 50, width: 50, lineHeight: 0
                }}>
                    <i style={{ fontSize: 24 }} className="bi bi-x text-white"></i>
                </span>
            } */}
            {gameExitPopup &&
                <>
                    <div className="modal-backdrop fade show"></div>
                    <div className={`modal fade shadow  ${gameExitPopup ? "show m-popup-show" : "false m-popup-hide"}`}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-md modal-dialog-centered justify-content-center">
                            <div className="m-popup-body justify-content-center moplay-corn-bg  align-items-center sx-bg-page position-relative">
                                <img loading="lazy" src={Resources.POPUP_HEADER_IMAGE} className='w-100 position-absolute' style={{ top: -30, left: 0 }} />
                                <div className="popup-heading">
                                    <div className="close-btn">
                                        <img loading="lazy" src={Resources.POPUP_CLOSE_ICON} alt="" onClick={() => setGameExitPopup(false)} />
                                    </div>
                                    <strong className='text-center w-100'>Exit?</strong>
                                </div>
                                <div className="modal-body py-4">
                                    <div className='d-flex justify-content-center py-4 fs-4'>
                                        Are you sure want to exit the game ?
                                    </div>
                                    <div className='d-flex justify-content-center border-bottom-0 border-top-0'>
                                        <button className='btn btn-secondary px-2 rounded-pill px-3 me-2' onClick={() => setGameExitPopup(false)}>Cancel</button>
                                        <button onClick={configGameExit} className='btn btn-primary px-4 rounded-pill px-3 cursor-pointer moplay-primary-bg ms-2'>Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const mapStateToProps = (state: any) => ({
    gameType: state.PlatformStore.gameType,
    multiplayerParticipants: state.PlatformStore.multiplayerParticipants,
    opponentGameData: state.PlatformStore.multiplayerOpponentGameData,
    multiplayerBotData: state.PlatformStore.multiplayerBotData,
    userData: state.UserStore.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    updateGameScore: (data: ScoreSubmitReq) => dispatch({ gamescore: data, type: 'gamescore' }),
    // updateMultiplayerMatchSummary: (data: MultiplayerGameSummary) => dispatch({ type: PlatformActionType.MULTIPLAYER_GAME_SUMMARY, data }),
    updateMultiplayerUserData: (data: MultiplayerGameData) => dispatch({ type: PlatformActionType.MULTIPLAYER_USER_DATA, data }),
    multiplayerGameEnd: () => dispatch({ type: PlatformActionType.MULTIPLAYER_GAME_END, data: { status: 1 } })
});
export default connect(mapStateToProps, mapDispatchToProps)(ScoreBar);