import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GAME_URL } from '../../config/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { Game } from '../model/dto/res/game';
import { connect } from 'react-redux';
import { PlatformActionType, PopupType } from '../redux/reducers/types';

interface Props {
    game: Game;
    userloginDetails?: any;
    updateUserLoginStatus?: () => void;
}

const GameCard = (props: Props) => {
    const navigation = useNavigate();
    const divElement = useRef();
    const [gameText, setGameText] = useState('');
    useEffect(() => {
        const contentElement: HTMLDivElement = divElement.current;
        if (contentElement) {
            setGameText(contentElement.innerText);
        }

    }, [divElement])

    const openGame = (event: SyntheticEvent) => {
        // if (!props?.userloginDetails) {
        //     if (props.updateUserLoginStatus) {
        //         props.updateUserLoginStatus();
        //     }
        // } else {
        // }
        event.stopPropagation();
        navigation(`/detail/${props.game?._id}?_sF=home-page`);
    }

    const onPlayGame = (event: SyntheticEvent) => {
        event.stopPropagation();
        navigation(`/play/${props.game?._id}?type=0`);
    }

    return (
        <div className="card pointer rounded-4 h-100" onClick={onPlayGame} style={{ paddingBottom: 90 }}>
            <img loading="lazy" src={GAME_URL + props.game?.icon} className={`card-image-top mp-game-icon rounded-top-4`} alt="Game Image" width="100%"
                height="100%" />

            {/* <Img loading="lazy" src={GAME_URL + props.game?.icon} className={`card-image-top mp-game-icon rounded-top-4`} alt="Game Image" width="182" 
  height="200" /> */}
            <div className="game-card-title py-2">
                <div className="d-flex align-items-center">
                    <strong className="f-18 ps-3 text-capitalize me-2">{props.game?.name}</strong>
                </div>
                <div className="d-flex px-3 justify-content-between">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon className='fs-12' icon={faStar}></FontAwesomeIcon>
                        <small className='ms-1'>{props.game?.avgRating || 0}</small>
                    </div>
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon className='fs-12' icon={faThumbsUp}></FontAwesomeIcon>
                        <small className='ms-1'>{props.game?.total_likes}</small>
                    </div>
                </div>
                <div className="d-none" ref={divElement} dangerouslySetInnerHTML={{ __html: props.game?.description }}></div>
                <div className="text-start px-3 text-capitalize description">{gameText}</div>
            </div>
            <button className="btn btn-sm btn-custom-olive position-absolute w-100" onClick={openGame} style={{ bottom: 45 }}>How to Play</button>
            <button className="btn btn-sm btn-primary position-absolute w-100 bottom-0" onClick={onPlayGame}>Play</button>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userloginDetails: state.UserStore.user
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserLoginStatus: () => dispatch({ data: PopupType.LOGIN, type: PlatformActionType.LOGIN }),
})

export default connect(mapStateToProps, mapDispatchToProps)(GameCard);
