import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import SignupPage from '../pages/signup-page';
import { Home } from '../pages/home'
import GameInfo from '../pages/game-info';
import PlayGame from '../pages/play-game';
import { SavedGames } from '../pages/saved-games';
import { Likes } from '../pages/likes';
import { RecentlyPlayedGames } from '../pages/recently-played-games';
import UserInfo from '../pages/user-info';
import GameResult from '../pages/game-result';
import Social from '../pages/social';
import { MobileViewMenu } from '../components/mobile-view-menu';
import { Shop } from '../pages/shop';
import Notifications from '../pages/notifications';
import WaitingRoom from '../app/pages/waiting-room';
import GameResultMultiplayer from '../pages/game-result-multiplayer';
import { SocketSvc } from '../app/service/socket.service';
import { connect } from 'react-redux';
// import Profile from '../app/pages/profile';
// import Challenges from '../app/pages/challenges';
// import ChallengeInfo from '../app/pages/challenge-info';
import { UserService } from '../app/service/user.service';
import Blog from '../app/pages/blog';
import BlogView from '../app/pages/blog-view';
// import HomePage from '../pages/home-page';
import { Contact } from '../app/pages/contact';
// import HeaderBar from '../components/header-bar';
import { Terms } from '../app/components/terms';
import { PlatformScreen } from '../app/redux/reducers/types';
import { Privacy } from '../app/pages/privacy';
// import Footer from '../app/components/footer';
import { AboutUs } from '../app/pages/about-us';
const Profile = React.lazy(() => import('../app/pages/profile'));
const Challenges = React.lazy(() => import('../app/pages/challenges'));
const ChallengeInfo = React.lazy(() => import('../app/pages/challenge-info'));

const HomePage = React.lazy(() => import('../pages/home-page'));
const HeaderBar = React.lazy(() => import('../components/header-bar'));
const Footer = React.lazy(() => import('../app/components/footer'));
// const Blog = React.lazy(() => import('../app/pages/blog'));


interface Props {
   updateUserLogin?: (data: any) => void;
   active_screen?: PlatformScreen;
   userdata: any;
}
const RootNavigation = (props: Props) => {
   const [userId, setUserId] = useState(null);

   useEffect(() => {
      console.log('props.userdata ', props.userdata);

   }, [])

   useEffect(() => {
      if (userId) {
         loadUserData();
      }
   }, [userId]);

   useEffect(() => {
      const userDataObj = localStorage.getItem('userData');
      if (userDataObj) {
         try {
            const userData = JSON.parse(userDataObj);
            if (props.updateUserLogin) {
               props.updateUserLogin(userData);
            }
            setUserId(userData._id);
            setTimeout(async () => {
               SocketSvc.connect(userData._id);
               SocketSvc.connect('login-updates');
               await UserService.updateUserSession(userData._id, SocketSvc.SocketID, userData.token);
            }, 5000);
         } catch (error) {

         }
      }
   }, []);

   const loadUserData = async () => {
      const userInfo = await UserService.getInfo();
      if (props.updateUserLogin && userInfo) {
         props.updateUserLogin(userInfo);
      }
   }
   return (
      <>
         {props.active_screen !== PlatformScreen.IN_GAME && <HeaderBar></HeaderBar>}
         <div className='position-relative d-flex'>
            <div className="d-block d-md-none">
               <input type="checkbox" name="" id="profile_toggler_checkbox" style={{ display: 'none' }} />
               <Profile canShowProfile={true} onClose={() => { }}></Profile>
            </div>
            <div className={`${props.userdata ? '' : ''} ${props.active_screen === PlatformScreen.IN_GAME ? 'no-header' : ''} section-content`}>
               <Routes>
                  {/* {window.innerWidth < 900 && <Route path="/" element={<Home />} />}
                  {window.innerWidth >= 900 && <Route path="/" element={<HomePage />} />} */}
                  {/* <Route path="/" element={<Blog />} /> */}
                  <Route path="/" element={<HomePage />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/home-page" element={<HomePage />} />
                  <Route path="/registration" element={<SignupPage />} />
                  <Route path="/detail/:id" element={<GameInfo />} />
                  <Route path="/play/:id" element={<PlayGame />} />
                  <Route path="/result/:id" element={<GameResult />} />
                  <Route path="/summary/:id" element={<GameResultMultiplayer />} />
                  <Route path="/saved-games" element={<SavedGames />} />
                  <Route path="/likes" element={<Likes />} />
                  <Route path="/recently-played" element={<RecentlyPlayedGames />} />
                  <Route path="/user-info" element={<UserInfo />} />
                  <Route path="/social" element={<Social />} />
                  <Route path="/shop" element={<Shop />} />
                  {/* <Route path="/notifications" element={<Notifications />} /> */}
                  <Route path='/waiting/:id' element={<WaitingRoom />} />
                  {/* <Route path="/profile" element={<Profile />} /> */}
                  <Route path="/challenges" element={<Challenges />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path='/blog/:blog_title' element={<BlogView />}></Route>
                  <Route path="/challenge/:id" element={<ChallengeInfo />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path='/about' element={<AboutUs />}></Route>
               </Routes>
            </div>
         </div>
         <div className={`${props.userdata ? '' : ''}`}>
            <Footer></Footer>
         </div>
      </>
   )
}

const mapStateToProps = (state: any) => ({
   active_screen: state.PlatformStore.active_screen,
   userdata: state.UserStore.user
});

const mapDispatchToProps = (dispatch: any) => ({
   updateUserLogin: (data: any) => dispatch({ user: data, type: 'login' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootNavigation);

// export default RootNavigation;
