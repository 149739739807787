import React, { SyntheticEvent, useEffect, useState } from 'react'
import { SocialService } from '../app/service/social.service';
import appConfig from '../config/constant';
import Chat from './chat';
import { GameChallenge } from '../app/service/game.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faDeleteLeft, faMessage, faTrash, faUserEdit, faUserInjured } from '@fortawesome/free-solid-svg-icons';
import { User } from '../app/model/dto/res/user';
import { connect } from 'react-redux';
import { CircleDot } from '../app/components/circle-dot';
import { MrPopup } from '../app/components/mr-popup';
import { NavLink } from 'react-router-dom';
import { UserActionType } from '../app/redux/reducers/types';
import { Resources } from '../app/utility/resources';
import DisplayPicture from './ui/display-picture';

interface Props {
    hideButtons?: any;
    // showChallegeStatus?: any;
    gameUserId?: any;
    score: number;
    userData?: User;
    friends_status_refresh_id?: string;
    updateWallet: (coins: number) => void;
}
const Friends = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [friendsData, setFriendsData] = useState<User[]>([]);
    const [filteredFriends, setFilteredFriends] = useState<User[]>([]);
    const [showChat, setShowChat] = useState<boolean>(false);
    const [showChallenge, setShowChallenge] = useState<boolean>(false);
    // const [challengeButtonStatus, setChallengeButtonStatus] = useState<boolean>(false);
    const [showChallengeUserData, setShowChallengeUserData] = useState<any>(null);
    const [selectedFriend, setSelectedFriend] = useState<any>(null);
    const [coins, setCoins] = useState<any>(0);
    const [message, setMessage] = useState<any>('');
    const [showCoinError, setShowCoinError] = useState<any>('')
    const [showMessageError, setShowMessageError] = useState<any>('');
    const [showCoinsPopup, setShowCoinsPopup] = useState(false);

    useEffect(() => {
        if (props.userData) {
            loadFriends();
        }
    }, [props.userData])

    useEffect(() => {
        if (props.userData) {
            loadFriends();
            setTimeout(() => {
            }, 2000);
        }
    }, [props.friends_status_refresh_id]);

    const loadFriends = () => {
        SocialService.GetFriends(props.userData._id).then((res: any) => {
            const friendsWithDisabled = res.map((friend: any) => ({
                ...friend,
                disabled: false
            }));
            setFriendsData(friendsWithDisabled);
            setFilteredFriends(friendsWithDisabled);
            // setFriendsData(res);
        })
    }

    const handleChat = (friend: any) => {
        setSelectedFriend(friend);
        setShowChat(true);
    }
    const getChatCloseStatus = (status: any) => {
        if (status) {
            setShowChat(false);
        }
    }

    const handleUnFollow = (data: any) => {
        setLoading(true);
        if (data?.social_id) {
            SocialService.UnFollowFreind(data?.social_id).then((res: any) => {
                setFriendsData(res);
                setLoading(false);
                loadFriends();
            })
        }
    }

    const handleChallege = (data: any) => {
        setShowChallenge(true);
        setShowChallengeUserData(data);
    }

    const incrementCoins = () => {
        setCoins(coins + 5);
        setShowCoinError("");
    };

    const decrementCoins = () => {
        if (coins >= 5) {
            setCoins(coins - 5);
        }
    };

    const handleChangeMessage = (e: any) => {
        const data = e.target.value
        if (data === "") {
            setShowMessageError("Please enter message");
        } else {
            setMessage(data);
            setShowMessageError("");
        }
    }

    const onSubmitChallengeCoins = () => {
        console.log('props ', props.userData);

        if (coins === 0) {
            setShowCoinError("Please add coins");
        } else if (message === "") {
            setShowMessageError("Please enter message")
        } else {
            if (props.userData && props.userData.wallet && props.userData.wallet.coins < coins) {
                setShowCoinsPopup(true);
                return;
            }
            if (props.updateWallet) {
                props.updateWallet(coins);
            }
            const gameChallengePayload = {
                game: props?.gameUserId,
                from: props.userData?._id,
                to: showChallengeUserData?._id,
                bet_amount: coins,
                message: message,
                score: props.score
            }
            GameChallenge(gameChallengePayload).then((res: any) => {
                setShowCoinError("");
                // setChallengeButtonStatus(true);
                const updatedFriendsData = friendsData.map((friend: any) =>
                    friend._id === showChallengeUserData._id ? { ...friend, disabled: true } : friend
                );
                setFriendsData(updatedFriendsData);
                setShowChallenge(false);
            }).catch(error => {
                console.error("Error while game challenge:", error);
                setShowCoinError("");
            });
            setShowCoinError("");
        }
    }

    const onChangeSearchHere = (event: SyntheticEvent) => {
        const inputEl = event.target as HTMLInputElement;
        const searchedList = friendsData.filter(el => el.name.toLocaleLowerCase().indexOf(inputEl.value.toLowerCase()) > -1);
        setFilteredFriends(searchedList);
    }

    return (
        <div className='mp-social-tab-container rounded-top-0 rounded-bottom text-dark py-2 pb-5'>
            <div className="px-3">
                <input type="text" className="form-control" placeholder='Search friend here' onChange={onChangeSearchHere} />
            </div>
            {showChat ?
                (
                    <Chat friend={selectedFriend} closeChat={(e: any) => getChatCloseStatus(e)} />
                ) : (
                    <>
                        {loading &&
                            <div className="text-center spinner">
                                <div className="spinner-border spinner-border-sm login-spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        }
                        {!loading && (
                            <div className='mt-3'>
                                {filteredFriends?.length > 0 && filteredFriends?.map((data: any, index: number) => (
                                    <div className='mx-2' key={index}>
                                        <div className='border-bottom py-2 px-2 mb-3'>
                                            <div className={`d-flex ${props?.hideButtons ? '' : 'flex-column'} flex-column justify-content-between`}>
                                                <div className='mb-3'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='position-relative'>
                                                            <DisplayPicture profile_pic={data?.profile_img} first_name={data?.name} last_name='' width={40} height={40}></DisplayPicture>
                                                            {data.is_online && <span className='position-absolute' style={{right: 0, bottom: 0}}><CircleDot color='#00ff00' size={10}></CircleDot></span>}
                                                        </div>
                                                        <div>
                                                            <div className='mx-2 d-flex align-items-center'>
                                                                <strong className='me-3 text-capitalize'>{data?.name}</strong>
                                                            </div>

                                                            <div className='mx-2 f-14'>{data?.address}</div>
                                                        </div>
                                                        {!props?.hideButtons && (
                                                            <div className='d-md-flex d-flex my-1 my-md-0 align-items-center justify-content-end' style={{ marginLeft: 'auto' }}>
                                                                <FontAwesomeIcon className='me-5 pointer' style={{ fontSize: 20 }} icon={faMessage} onClick={() => handleChat(data)}></FontAwesomeIcon>
                                                                <FontAwesomeIcon className='pointer' icon={faTrash} style={{ fontSize: 20 }} onClick={() => handleUnFollow(data)}></FontAwesomeIcon>
                                                            </div>
                                                        )}
                                                        {props?.hideButtons && (
                                                            <div className='d-md-flex my-3 d-flex '>
                                                                <button className={`btn btn-sm btn-primary py-1 mx-1 mx-md-2 rounded-pill`} disabled={data?.disabled} onClick={() => handleChallege(data)}>{data?.disabled ? "Challenged" : "Challenge"}</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {filteredFriends?.length === 0 && (
                                    <p className='text-center my-5 align-items-center'>Your friend's list is empty <br/>Go to Find People and start making friends</p>
                                )}
                            </div>
                        )}
                    </>
                )}
            {showChallenge &&
                <>
                    <div className={`modal shadow fade text-dark ${showChallenge ? "show m-popup-show" : "false m-popup-hide"}`}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true" >
                        <div className="modal-dialog  modal-dialog-centered">
                            <div className="modal-content moplay-corn-bg">
                                <img loading="lazy" src={Resources.POPUP_HEADER_IMAGE} className='w-100 position-absolute' style={{ top: -70, left: 0 }} />
                                <div className="popup-heading">
                                    <h4 className='text-center w-100'>Challenge</h4>
                                    <div className="close-btn">
                                        <img loading="lazy" src={Resources.POPUP_CLOSE_ICON} alt="" onClick={() => setShowChallenge(false)} />
                                    </div>
                                </div>
                                <div className="modal-body pt-5">
                                    <div className='mx-3'>
                                        <div className='text-center  py-lg-1 my-lg-2 mx-3'>
                                            <div className='d-md-flex justify-content-center'>
                                                <div className='m-auto f-18'>Challenge coins </div>
                                                <div className='m-auto d-flex justify-content-between my-3 my-md-0'>
                                                    <div><button className={`btn btn-sm btn-secondary  ${coins <= 0 ? "mp-cursor-pointer-not-allowed" : "mp-cursor-pointer"}`} disabled={coins === 0} onClick={decrementCoins}><i className="bi bi-dash fw-bold fs-5"></i></button></div>
                                                    <div className='m-auto mx-5 fs-4'>{coins}</div>
                                                    <div><button className='btn btn-sm btn-primary' onClick={incrementCoins}><i className="bi bi-plus fs-5"></i></button></div>
                                                </div>
                                            </div>
                                            {showCoinError && <div className='text-danger text-start'>{showCoinError}</div>}
                                        </div>
                                        <div>
                                            <label className='form-label d-flex justify-content-start'>Message</label>
                                            <input type="text" name="message" className="form-control" placeholder='Please enter message' defaultValue={message} onChange={handleChangeMessage} />
                                            {showMessageError && <div className='text-danger text-start'>{showMessageError}</div>}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-around py-2 my-3'>
                                        <div>
                                            <button className='btn btn-primary px-5' onClick={onSubmitChallengeCoins}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {showCoinsPopup && <div className="text-white">
                <MrPopup content='You do not have enough coins. Please update your wallet' heading='Insufficient Coins' onClose={() => setShowCoinsPopup(false)}>
                    {/* <NavLink to={'/shop'}><button className='btn btn-primary'>Shop</button></NavLink> */}
                </MrPopup>
            </div>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userData: state.UserStore.user,
    friends_status_refresh_id: state.PlatformStore.friends_status_refresh_id
});

const mapDispatchToProps = (dispatch: any) => ({
    updateWallet: (coins: number) => dispatch({ type: UserActionType.SPEND_COINS, data: coins }),
})
export default connect(mapStateToProps, mapDispatchToProps)(Friends);