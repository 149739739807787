import React, { useEffect, useRef, useState } from 'react'
import { Blog } from '../model/blog'
import DisplayPicture from '../components/display-picture';
interface Props {
    blog: Blog;
}
export const BlogContent = (props: Props) => {
    const divElement = useRef();
    const [blogText, setBlogText] = useState('');
    useEffect(() => {
        const contentElement: HTMLDivElement = divElement.current;
        if (contentElement) {
            setBlogText(contentElement.innerText);
        }

    }, [divElement])

    return (
        <div className='border-box mb-10 ph-30 pv-10 ph-md-10 blog-item'>
            <div className="flex-box align center mb-10">
                <DisplayPicture width={50} height={50} first_name={props.blog.user_name || ''} last_name='' profile_pic={props.blog.user_profile_pic}></DisplayPicture>
                <div className='ml-10 clr-reg  flex-box column'>
                    <b className='mb-1'>{props.blog.user_name}</b>
                    <small>
                        {`${new Date(props.blog.created_dt).getDate()}-${new Date().getMonth() + 1}-${new Date(props.blog.created_dt).getFullYear()}`}
                    </small>
                </div>
            </div>
            <div className="flex-box content-item mb-10">
                {props.blog.poster_url_display && <img loading="lazy" style={{ width: 300, height: 150 }} src={props.blog.poster_url_display} className='w-full cover rounded mr-10' />}
                <div>
                    <h2 className='clr-reg' style={{ fontSize: 20 }}>{props.blog.title}</h2>
                    <div  ref={divElement} className='d-none' dangerouslySetInnerHTML={{ __html: props.blog.content[0].content }}></div>
                    <div className="description">{blogText}</div>
                </div>
            </div>
            {
                props.blog.tags && props.blog.tags.length > 0 && <div className="">
                    {props.blog.tags.map(tagEl => <span className='clr-light mr-20 inline-block'>#{tagEl}</span>)}
                </div>
            }
        </div>
    )
}
