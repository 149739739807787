import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { SideMenu } from '../components/side-menu';
import MoplayLogoImage from './../assets/images/icons/moplay.png';
import HeaderBar from '../components/header-bar';
import { Resources } from '../app/utility/resources';

interface Props {

}

export const Shop = (props: Props) => {
    const [previousPosition, setPreviousPosition] = useState(0);
    const [activeElement, setActiveElement] = useState<string | number>(1);
    const [nextElement, setNextElement] = useState<string | number>(1);
    const [userFlowActionView, setUseerFlowActionview] = useState<boolean>(false);
    const [themeStatus, setThemeStatus] = useState<any>(true);
    const [searchValue, setSearchValue] = useState('');
    const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
    const [userLoginData, setUserLoginData] = useState('');
    const loginPopupRef: any = useRef();

    const shopProductsData: any = [
        {
            id: "1",
            name: "Buy 250 coins",
            image: Resources.COIN_PNG
        },
        {
            id: "2",
            name: "Buy 1500 coins",
            image: Resources.COIN_PNG
        },
        {
            id: "3",
            name: "Buy 5000 coins",
            image: Resources.COIN_PNG
        }
    ]

    useEffect(() => {
        if (loginPopupRef) {
            setUseerFlowActionview(false)
        }
        (window as any).gtag('event', 'Shop');
    }, [loginPopupRef])

    const handleScroll = (event: SyntheticEvent) => {
        const target = event.target as HTMLElement;
        const scrollDiff = target.scrollHeight - target.scrollTop;
        if (target.scrollTop - previousPosition > 250) {
            setPreviousPosition(target.scrollTop);

        } else if (target.scrollTop - previousPosition < -250) {
            setPreviousPosition(target.scrollTop);
        }
        if (activeElement !== nextElement) {
            setActiveElement(nextElement);
            event.stopPropagation();
            event.preventDefault();
            const elementDom = document.getElementById(`video_element_${nextElement}`);
            if (elementDom) {
                elementDom.scrollIntoView({ behavior: 'smooth', block: 'end' });
                window.scrollTo(0, 0);
            }
        }
    }

    function getThemeStatus(status: boolean) {
        setThemeStatus(status);
    }

    function findData(data: any) {
        setSearchValue(data);
    }

    function userLogInData(data: any) {
        setUserLoginData(data)
    }

    const getLoginStatus = (status: any) => {
        setShowLoginPopup(false)
        setUseerFlowActionview(false);
    }
    return (
        <div>
            <div className={`mp-scroll-bar-width container-fluid pe-3 scroll-container  position-relative`} ref={loginPopupRef} onScroll={handleScroll}>
                <div className='row'>
                    <div className='col-md-12 px-4'>
                        <div className='my-2 fs-4 title-reg'>Shop</div>
                        <div className='row my-3'>
                            {shopProductsData?.map((data:any, index:number) =>(
                                <div key={index} className='col-4 my-2'>
                                    <div className='text-center'>
                                        <img loading="lazy" src={data?.image} className='mp-shop-image'/>
                                    </div>
                                    <div className='text-center  my-2 fw-bold'>{data?.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
