import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { MrPopup } from './mr-popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faLock } from '@fortawesome/free-solid-svg-icons'
import { User } from '../model/dto/res/user'
import { UserActionType } from '../redux/reducers/types'
import { UserService } from '../service/user.service'
import { Resources } from '../utility/resources';

interface Props {
    User?: User;
    updateWallet?: (coins: number) => void;
    onClose: () => void;
}
const DailyBonus = (props: Props) => {
    const stopPropagation = () => {

    }

    const onCollectCoins = async (day: number) => {
        if (day === props.User.login_day) {
            if (props.User && props.updateWallet) {
                let collectedCoins = props.User.login_day * 50 + 50;
                if (props.User.login_day > 7) {
                    collectedCoins = 1000;
                }
                props.updateWallet(collectedCoins);
                await UserService.updateWallet(collectedCoins);
                props.onClose();
            }
        }
    }

    return (
        <div className="m-popup full-cover">
            <div className="m-popup-body justify-content-center moplay-corn-bg  align-items-center sx-bg-page position-relative " onClick={stopPropagation}>
                <img loading="lazy" src={Resources.POPUP_HEADER_IMAGE} className='w-100 position-absolute' style={{ top: -70 }} />
                <div className="popup-heading">
                    <strong className='text-center w-100'>Daily Bonus</strong>
                    <div className="close-btn">
                        <img loading="lazy" src={Resources.POPUP_CLOSE_ICON} alt="" onClick={props.onClose} />
                        {/* <FontAwesomeIcon icon={faClose} className='close-btn' onClick={props.onClose}></FontAwesomeIcon> */}
                    </div>
                </div>
                <div className="m-popup-content daily-bonus" style={{
                    maxHeight: '70vh',
                    overflowY: 'auto'
                }}>
                    <div className="d-grid mt-4" style={{
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gap: 10
                    }}>
                        {
                            Array.from([0, 1, 2, 3, 4, 5]).map(el =>
                                <div className={`bonus-card  d-flex flex-column align-items-center justify-content-center position-relative 
                                ${(props.User && props.User.login_day > el) ? 'collected' : ''}
                                ${(props.User && props.User.login_day === el) ? 'collect' : ''}
                                `} onClick={() => onCollectCoins(el)}>
                                    <strong className='day-info'>Day {el + 1}</strong>
                                    <div className='coins'>
                                        <img loading="lazy" className="position-absolute" src={Resources.BONUS_COIN_PNG} alt="" />
                                        <img loading="lazy" style={{ bottom: 0 }} className="position-absolute" src={Resources.BONUS_COINS_PNG} alt="" />
                                    </div>
                                    <span className='text-white pb-4'>{el * 50 + 50}</span>
                                    <div className="w-100 position-absolute text-center collection-container">
                                        {props.User && props.User.login_day > el &&
                                            <button className='btn btn-sm px-2 py-1 '>Collected</button>
                                        }
                                        {props.User && props.User.login_day === el &&
                                            <button className='btn btn-primary px-2 py-1 text-dark  f-14'><b>Collect</b></button>
                                        }
                                        {props.User && props.User.login_day < el &&
                                            <button className='btn btn-primary px-2 py-1 text-dark  f-14'>
                                                <b>Locked</b>
                                                <FontAwesomeIcon className='ms-2' icon={faLock}></FontAwesomeIcon>
                                            </button>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {/* <div className="border rounded d-flex flex-column align-items-center justify-content-center position-relative" style={{ height: 100, minWidth: 70 }} onClick={onCollectCoins}>
                            <span>Day 8</span>
                            <span>{1000}</span>
                            {props.User && props.User.login_day > 7 && <div className="w-100 position-absolute text-center" style={{ bottom: 0 }}>
                                <button className='btn btn-primary p-0 w-100'>Collect</button>
                            </div>}
                        </div> */}
                    </div>
                </div>
                <div className="m-actions">
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    User: state.UserStore?.user
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateWallet: (coins: number) => dispatch({ type: UserActionType.COLLECT_DAILY_BONUS, data: coins }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyBonus);